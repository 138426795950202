import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
        path: '/',
        name: 'home',
        component: () => import('./views/Form.vue')
    },
    {
        path: '/medyk-oferta',
        name: 'medyk-oferta',
        component: () => import('./views/medyk-oferta.vue')
    },
    {
      path: '/flota-pr',
      name: 'flota-pr',
      component: () => import('./views/flota-pr.vue')
    },
    {
      path: '/flota-ab',
      name: 'flota-ab',
      component: () => import('./views/flota-ab.vue')
    },
    {
        path: '*',
        name: '404',
        component: () => import('./views/404.vue')
    }
  ]
})
